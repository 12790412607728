import { h } from 'preact';
import { Router } from 'preact-router';
import { useState } from 'preact/hooks';
import Header from './header';

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';


const App = () => {
	const [currentTheme, setTheme] = useState(localStorage.getItem("theme") || "dark");

	const toggle = () => {
		if (currentTheme === 'dark') {
			document.documentElement.classList.remove('dark')
			localStorage.theme = 'light'
		} else {
			localStorage.theme = 'dark'
			document.documentElement.classList.add('dark')
		}
		
		setTheme(currentTheme == "light" ? "dark" : "light");
	};

	return (
		<div id="app" class="bg-white dark:bg-gray-900">
			<Header toggler={toggle} />
			<Home />
		</div>
	);
}

export default App;
