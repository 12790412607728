import { h } from 'preact';
import { Link } from 'preact-router/match';
import style from './style.css';

const Header = ({toggler}) => (
	<div class="right-2 top-2 absolute hover:cursor-pointer" onClick={toggler}>
		<p class="text-gray-800 dark:text-gray-400 select-none text-sm font-sans">toggle</p>
	</div>
);

export default Header;
